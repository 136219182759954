<template>
  <div id="app" class="app" :class="{ 'sidebar-enable': menuOpen }">
    <!-- <Preloader :class="{ 'preloader-hide': isHide }" /> -->
    <Topbar v-if="authorized" />
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import Preloader from "@/components/preloader";
import Topbar from "@/components/Topbar";

export default {
  components: {
    Preloader,
    Topbar,
  },
  created() {
    this.$i18n.locale = this.lang
  },
  updated() {
    this.isHide = true;
  },
  data() {
    return {
      isHide: false,
    };
  },
  computed: {
    ...mapState(useGlobalStore, ['lang', 'menuOpen', 'authorized']),
  },
  watch: {
    lang() {
      this.$i18n.locale = this.lang
    },
  }
};
</script>

<style lang="scss"></style>
