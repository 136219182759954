import Vue from "vue"
import axios from "axios";
import { useGlobalStore } from '@/store/global'
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.get["Accept"] = "application/json";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.request.use(
  (config) => {
    const store = useGlobalStore()
    const token = store.token;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  // (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.log('axios.interceptors.response.use, error: ', error)
    // console.log('axios.interceptors.response.use, error, status: ', status)

    const store = useGlobalStore()

    const {
      config,
      // eslint-disable-next-line no-unused-vars
      response: { status, data },
    } = error;
    const originalRequest = config;

    console.log('axios.interceptors.response.use, status: ', status)

    // Return any error which is not due to authentication back to the calling service
    if (status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    // logout and return error when authentication and credentials are wrong
    if (status === 401 && originalRequest.url.includes("api-token")) {
      // console.log('logout and return error when authentication and credentials are wrong...')

      // store.logout().then(() => {
      //   router.push("/login");
      // });
      router.push("/logout").catch(() => { });

      return Promise.reject(error);
    }
    // Try to refresh an existing token : user pushed the reload button on browser
    // this situation should not happen as reload is managed in router
    if (status === 401 && store.authorized) {
      store
        .reload()
        .then((response) => {
          console.log('axios.interceptors.response.use, dispatch(reload), response: ', response);
          router.push(originalRequest.url).catch(() => { });
        })
        .catch((error) => {
          console.log('axios.interceptors.response.use, (status === 401 && store.authorized): ', error);
          router.push("/login").catch(() => { });
        });
    }
    // default for all others 401
    return Promise.reject(error);
  }
);

// Vue.prototype.$axios = axios;
export default axios;
