import { useGlobalStore } from '@/store/global'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let nextUrl = null;

const router = new Router({
  mode: 'history',
  //base: '/sub-path/',
  routes: [
    {
      path: "*",
      meta: {
        requiresAuth: false,
        // requiresRoles: ["a", "v", "d", "c"],
      },
      redirect: {
        path: "/",
      },
    },
    {
      path: '/',
      alias: '/main',
      name: 'main',
      meta: {
        requiresAuth: true,
        requiresRoles: ["a", "c"],
      },
      component: () => import("../views/pages/main"),
    },
    {
      path: "/login",
      name: "login",
      meta: {
        requiresAuth: false,
      },
      component: () => import("../views/pages/login"),
    },
    {
      path: "/privacy",
      name: "privacy",
      meta: {
        requiresAuth: false,
      },
      component: () => import("../views/pages/privacy"),
    },
    // {
    //   path: "/signup",
    //   name: "signup",
    //   meta: {
    //     requiresAuth: false,
    //   },
    //   component: () => import("../views/pages/signup"),
    // },
    // {
    //   path: "/forgot-password",
    //   name: "forgot-password",
    //   meta: {
    //     requiresAuth: false,
    //   },
    //   component: () => import("../views/pages/forgot-password"),
    // },

    // {
    //   path: "/faq",
    //   name: "faq",
    //   meta: {
    //     requiresAuth: true,
    //     requiresRoles: ["a", "c"],
    //   },
    //   component: () => import("../views/pages/faq"),
    // },

    // Admin pages
    {
      path: "/admin/leaderboard",
      name: "admin-leaderboard",
      meta: {
        requiresAuth: true,
        requiresRoles: ["a"],
      },
      component: () => import("../components/page-leaderboard"),
    },
    {
      path: "/admin/users",
      name: "admin-users",
      meta: {
        requiresAuth: true,
        requiresRoles: ["a"],
      },
      component: () => import("../components/page-users"),
    },
    {
      path: "/admin/users/:id",
      name: "admin-user",
      meta: {
        requiresAuth: true,
        requiresRoles: ["a"],
      },
      component: () => import("../components/page-user"),
    },
    {
      path: "/admin/companies",
      name: "admin-companies",
      meta: {
        requiresAuth: true,
        requiresRoles: ["a"],
      },
      component: () => import("../views/pages/admin/companies"),
    },
    {
      path: "/admin/stats",
      name: "admin-stats",
      meta: {
        requiresAuth: true,
        requiresRoles: ["a"],
      },
      component: () => import("../components/page-stats"),
    },

    // Company pages
    {
      path: "/company/leaderboard",
      name: "company-leaderboard",
      meta: {
        requiresAuth: true,
        requiresRoles: ["c"],
      },
      component: () => import("../components/page-leaderboard"),
    },
    {
      path: "/company/users",
      name: "company-users",
      meta: {
        requiresAuth: true,
        requiresRoles: ["c"],
      },
      component: () => import("../components/page-users"),
    },
    {
      path: "/company/users/:id",
      name: "company-user",
      meta: {
        requiresAuth: true,
        requiresRoles: ["c"],
      },
      component: () => import("../components/page-user"),
    },
    {
      path: "/company/trackers",
      name: "company-trackers",
      meta: {
        requiresAuth: true,
        requiresRoles: ["c"],
      },
      component: () => import("../views/pages/company/trackers"),
    },
    {
      path: "/company/stats",
      name: "company-stats",
      meta: {
        requiresAuth: true,
        requiresRoles: ["c"],
      },
      component: () => import("../components/page-stats"),
    },
  ]
})


// Fix, silence NavigationDuplicated error
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

// Route guard checks to see if you are logged in
// insure your are routed back to requested url after login
router.beforeEach((to, from, next) => {
  // console.log('to: ', to)

  const store = useGlobalStore()

  // if logout page - no need req user data, go to login page
  if (to.name === 'login') {
    next()
  } else if (to.name === 'logout') {
    store
      .logout()
      .then((response) => {
        next("/login")
      })
      .catch((error) => { });
  } else {
    // console.log('to: ', to)
    // console.log('from: ', from)

    // except on same page
    if (to.name === from.name) {
      next()
    } else {
      store
        .reload()
        .then((response) => {
          if (to.matched.some((record) => record.meta.requiresAuth)) {
            checkAuthAndRoles(to, from, next);
          } else {
            next()
          }
        })
        .catch((error) => { });
    }
  }

  // if (to.meta?.layout) {
  //   if (store.authorized) {
  //     store.setLayout(to.meta.layout)
  //   } else {
  //     store.setLayout(layouts.contenOnly)
  //   }
  // }
});

function checkAuthAndRoles(to, from, next) {
  // console.log('checkAuthAndRoles...')

  const store = useGlobalStore()

  // Check Auth and Roles
  if (store.authorized) {
    // check requiresRoles and user.roles in requiresRoles
    if (to.meta.requiresRoles?.length) {
      // console.log("Page require requiresRoles: ", to.meta.requiresRoles);
      let matchRoles = false;

      if (store.userRoles?.length) {
        matchRoles = store.userRoles.some((r) =>
          to.meta.requiresRoles.includes(r)
        );
      }

      // console.log("store.userRoles: ", store.userRoles);
      // console.log("to.meta.requiresRoles: ", to.meta.requiresRoles);

      if (!matchRoles) {
        // console.log("Routing: User NOT has needed premission");
        const prevpage = from.path ? from.path : "/";
        next(prevpage);
        return;
      } else {
        // console.log("Routing: User has needed premission");
      }
    } else {
      // console.log("Routing: No req roles needed - common page");
    }

    if (nextUrl) {
      // case I have next url, I have been redirected
      const url = nextUrl;
      nextUrl = null;
      next(url);
      return;
    }

    // case no next : most situation
    next();
    return;
  } else {
    next("/login");
  }
}

// set Splashscreen
// router.afterEach((to, from) => {
//   setTimeout(() => {
//     store.commit('setSplashScreen', false)
//   }, 500)
// })

export default router