// import crawlers from '@/data/crawlers'

export default {
  scrollTo: (x = 0, y = 0) => {
    window.scrollTo(x, y)
  },

  formatDate_YYYY_MM_DD: (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  },


}

// use in SFC:
// this.$helpers.scrollTo()
// this.$helpers.formatDate_YYYY_MM_DD(date)
