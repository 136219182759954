module.exports = {

  // ===== PUBLIC (global), no auth =====
  URL_TIMEZONE: "adm/timezone", // get, no params

  // ===== AUTH =====
  URL_LOGIN: "adm/auth/login", // post, email, password
  URL_LOGOUT: "adm/auth/logout", // get, auth (token)
  URL_PROFILE: "adm/auth/profile", // get, auth (token)

  // ===== CLIENT =====
  URL_CLIENT_LIST: "adm/client/list", // get, auth (token), (pager fields, company_id)

  // get (id), 
  // post (fio, phone, email ,password, company_id), 
  // put (id, block, name, fio, phone, password)
  URL_CLIENT_INFO: "adm/client/info",

  // ===== COMPANY =====
  URL_COMPANY_LIST: "adm/company/list", // get, admin: query (pager fields)
  URL_COMPANY_SEARCH: "adm/company/search", // get, admin: qname, query (Company quick search, only title)

  // get, admin, id
  // post, admin, name, fio, phone, email, password, 
  // put, admin, id, block, name, fio, phone, email, password
  URL_COMPANY_INFO: "adm/company/info",

  // ===== TRACKER =====
  URL_TRACKER_LIST: "adm/tracker/list", // get, admin, company: query 

  // get, admin, company: id
  // post, admin, company: name, number, mac, company_id
  // put, admin, company: id, name, number, mac, company_id
  URL_TRACKER_INFO: "adm/tracker/info", // 

  // ===== WORKOUT =====
  // post, admin, company: "day_from": "2023-10-10",  "day_to": "2023-10-17", "client_id": 25
  URL_WORKOUT: "adm/workout/list",
  // post, admin, company: "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
  URL_WORKOUT_GROUP: "adm/workout/grouplist",
  // post, admin, company: "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
  URL_WORKOUT_GROUP_DAY: "adm/workout/groupdaylist",

};
