import axios from "@/plugins/axios";
import urls from "@/urls"; // api endpoints
import { defineStore } from 'pinia';
import router from "@/router";

export const useGlobalStore = defineStore('global', {
  state: () => ({
    lang: "en",
    menuOpen: false,
    token: "",
    user: null,
    timezones: [],
  }),
  getters: {
    userRoles: (state) => state.user?.utype ? [state.user.utype] : [],
    authorized: (state) => !!state.token,

  },
  actions: {
    setLang(payload) {
      this.lang = payload
    },
    setMenuOpen(payload) {
      this.menuOpen = payload
    },
    setToken(payload) {
      this.token = payload
    },
    setUser(payload) {
      this.user = payload
    },
    setLogout() {
      this.token = ""
      this.user = null
    },

    async logout() {
      if (this.authorized) {
        try {
          await axios.get(urls.URL_LOGOUT)
        } catch (error) { }
      }

      this.setLogout()
      router.push("/login").catch(() => { })
    },

    async login({ email, password }) {
      try {
        const response = await axios.post(urls.URL_LOGIN, { email, password })
        // console.log('A login, success, response: ', response)
        this.setToken(response.data.token)
        this.setUser({ ...response.data.user, roles: response.data.roles }) // add roles to user data

        return {
          ok: true
        }
      } catch (error) {
        console.log('A login, error: ', error)
        await this.logout()
        return error
      }
    },

    async reload() {
      // console.log('A reload...')
      // await this.dispatch("fetchSysOpt"); // fire any reload

      if (!this.timezones?.length) {
        await this.getTimezone()
      }

      if (this.authorized) {
        await this.getUserData()
      }
      // // } else {
      // //  await this.logout()
      // // }
    },

    async getTimezone() {
      try {
        const { data } = await axios.get(urls.URL_TIMEZONE)
        // console.log('A getTimezone, success, data: ', data)

        this.timezones = data?.groups || []
      } catch (error) {
        console.log('A getTimezone, error: ', error)
      }
    },

    async getUserData() {
      try {
        const { data } = await axios.get(urls.URL_PROFILE)
        // console.log('A getUserData, success, data: ', data)

        // if user.block - logout // 0 - normal, 1 - blocked 
        if (data?.user?.block) {
          // console.log('User blocked - logout...')
          await this.logout()
        }

        this.setUser({ ...data.user, roles: data.roles }) // add roles to user data
      } catch (error) {
        console.log('A getUserData, error: ', error)
        await this.logout();
      }
    },

    async getCompanyList(params = {
      per_page: 25,
      page: 1,
    }) {
      // console.log('getCompanyList, params: ', params)

      return await axios.get(urls.URL_COMPANY_LIST, {
        headers: {
          'lang': this.lang
        },
        params: { ...params },
      }).then((response) => {
        // console.log('getCompanyList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getCompanyList, error: ', error)
          return error
        })
    },

    async searchCompanyList(params = {
      per_page: 25,
      page: 1,
      qname: "",
    }) {
      // console.log('searchCompanyList, params: ', params)

      return await axios.get(urls.URL_COMPANY_SEARCH, {
        headers: {
          'lang': this.lang
        },
        params: { ...params },
      }).then((response) => {
        // console.log('searchCompanyList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('searchCompanyList, error: ', error)
          return error
        })
    },

    async createCompany(data) {
      // name, fio, phone, email, password, 
      // console.log('createCompany, data: ', data)

      return await axios({
        method: 'post',
        url: urls.URL_COMPANY_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('createCompany, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('createCompany, error: ', error)
          return error
        })
    },

    async editCompany(data) {
      // id, block, name, fio, phone, email, password
      // console.log('editCompany, data: ', data)

      if (!data.id) {
        return
      }

      return await axios({
        method: 'put',
        url: urls.URL_COMPANY_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('editCompany, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('editCompany, error: ', error)
          return error
        })
    },

    async getClientList(params = {
      per_page: 25,
      page: 1,
      // company_id: "",
    }) {
      // console.log('getClientList, params: ', params)

      return await axios.get(urls.URL_CLIENT_LIST, {
        headers: {
          'lang': this.lang
        },
        params: { ...params },
      }).then((response) => {
        // console.log('getClientList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getClientList, error: ', error)
          return error
        })
    },

    async createClient(data) {
      // post (fio, phone, email ,password, company_id),   
      // console.log('createClient, data: ', data)

      return await axios({
        method: 'post',
        url: urls.URL_CLIENT_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('createClient, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('createClient, error: ', error)
          return error
        })
    },

    async editClient(data) {
      // put (id, block, name, fio, phone, password)
      // console.log('editClient, data: ', data)

      if (!data.id) {
        return
      }

      return await axios({
        method: 'put',
        url: urls.URL_CLIENT_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('editClient, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('editClient, error: ', error)
          return error
        })
    },

    // get, admin, client: id
    async getClient(id) {
      // console.log('getClient, data: ', id)

      if (!id) {
        return
      }

      return await axios.get(urls.URL_CLIENT_INFO, {
        headers: {
          'lang': this.lang
        },
        params: { id },
      }).then((response) => {
        // console.log('getClient, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getClient, error: ', error)
          return error
        })
    },

    async getTrackerList(params = {
      per_page: 25,
      page: 1,
    }) {
      // console.log('getTrackerList, params: ', params)

      return await axios.get(urls.URL_TRACKER_LIST, {
        headers: {
          'lang': this.lang
        },
        params: { ...params },
      }).then((response) => {
        // console.log('getTrackerList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getTrackerList, error: ', error)
          return error
        })
    },

    // get, admin, company: id
    async getTracker(id) {
      // console.log('getTracker, data: ', id)

      if (!id) {
        return
      }

      return await axios.get(urls.URL_TRACKER_INFO, {
        headers: {
          'lang': this.lang
        },
        params: { id },
      }).then((response) => {
        // console.log('getTracker, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getTracker, error: ', error)
          return error
        })
    },

    async createTracker(data) {
      // post, admin, company:  data = [{name, number, mac, company_id},{...}]
      // console.log('createTracker, data: ', data)

      return await axios({
        method: 'post',
        url: urls.URL_TRACKER_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('createTracker, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('createTracker, error: ', error)
          return error
        })
    },

    async editTracker(data) {
      // put, admin, company: id, name, number, mac, company_id
      // console.log('editTracker, data: ', data)

      if (!data.id) {
        return
      }

      return await axios({
        method: 'put',
        url: urls.URL_TRACKER_INFO,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('editTracker, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('editTracker, error: ', error)
          return error
        })
    },

    async getWorkout(data) {
      // post, admin, company: "day_from": "2023-10-10",  "day_to": "2023-10-17", "client_id": 25
      // console.log('getWorkout, data: ', data)

      if (!data?.client_id || !data?.day_from || !data?.day_to) {
        return
      }

      return await axios({
        method: 'post',
        url: urls.URL_WORKOUT,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('getWorkout, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('getWorkout, error: ', error)
          return error
        })
    },

    async leadersList(data) {
      // post, admin, company: "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
      // console.log('leadersList, data: ', data)

      return await axios({
        method: 'post',
        url: urls.URL_WORKOUT_GROUP,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('leadersList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('leadersList, error: ', error)
          return error
        })
    },

    async leadersDayList(data) {
      // post, admin, company: "day_from": "2023-10-01", "day_to": "2023-10-27", "company_id": 25, "page": 0, "sort": "", "sortd": "a"
      // console.log('leadersDayList, data: ', data)

      return await axios({
        method: 'post',
        url: urls.URL_WORKOUT_GROUP_DAY,
        data,
        headers: {
          'lang': this.lang
        },
      }).then((response) => {
        // console.log('leadersDayList, response: ', response)
        return response
      })
        .catch((error) => {
          console.log('leadersDayList, error: ', error)
          return error
        })
    },

  },
  persist: [
    {
      storage: localStorage,
      paths: ['lang', 'menuOpen'],
    },
    {
      storage: sessionStorage,
      paths: ['token', 'timezones'],
    },
  ],
})