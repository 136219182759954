<template>
  <div class="select-lang">
    <a v-for="locale in $i18n.availableLocales" :key="locale" :title="$t(`languages.${locale}`)" href="#"
      @click.prevent="setLang(locale)" :class="{ 'active': locale === lang }">{{ locale
      }}</a>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(useGlobalStore, ['lang']),
  },
  methods: {
    ...mapActions(useGlobalStore, ['setLang']),
  },
};
</script>
<style scoped lang="scss">
.select-lang {

  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin: 0 0 0 16px;
  }

  a:first-child {
    margin-left: 0;
  }

  a:hover,
  a:visited {
    color: #fff;
    text-decoration: none;
  }

  a.active {
    text-decoration: underline;
  }
}
</style>
