<template>
  <div>
    <!-- hide if landing (main) -->
    <!-- <div class="navbar-custom" v-if="$route.name !== 'main'"> -->

    <div class="navbar-custom">
      <div class="row align-items-center">
        <div v-if="!authorized || public" class="col-auto">
          <div class="logo-box">
            <router-link to="/" class="logo logo-light text-center">
              <span class="logo-lg">
                <img
                  src="@/assets/images/logo.svg"
                  :alt="$t('app.title')"
                  width="118"
                  height="auto"
                />
              </span>
            </router-link>
          </div>
        </div>

        <div class="col-auto">
          <ul
            v-if="authorized"
            class="list-unstyled topnav-menu topnav-menu-left m-0"
          >
            <li>
              <button
                class="button-menu-mobile waves-effect waves-light"
                @click="toggleMenu"
              >
                <span class="material-symbols-rounded">menu</span>
              </button>
            </li>

            <li>
              <a
                class="navbar-toggle nav-link"
                data-toggle="collapse"
                @click="horizonalmenu()"
                data-target="#topnav-menu-content"
              >
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="col">
          <div class="col col-lg-10 col-xl-8 mx-auto"></div>
        </div>
        <div class="col-auto">
          <langSelect />
        </div>
        <div class="col-auto">
          <ul class="list-unstyled topnav-menu float-right mb-0">
            <!-- <template v-if="!authorized">
              <li>
                <router-link to="/signup" class="nav-link">{{ $t('sign-up') }}</router-link>
              </li>
              <li>
                <router-link to="/login" class="nav-link">{{ $t('log-in') }}</router-link>
              </li>
            </template> -->
            <li v-if="authorized">
              <div class="user-info">{{ owner.name }} ({{ owner.email }})</div>
            </li>
            <li v-if="authorized">
              <a
                class="nav-link"
                href="/logout"
                @click.prevent="handleLogout"
                :title="$t('log-out')"
              >
                <span class="material-symbols-rounded v-middle">logout</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";
import router from "@/router";
import axios from "axios";
import urls from "@/urls";
import langSelect from "@/components/lang-select";

export default {
  components: {
    langSelect,
  },
  props: {
    public: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wait: false,
    };
  },
  mounted() {},
  created() {},
  beforeDestroy() {},
  computed: {
    ...mapState(useGlobalStore, ["authorized", "user", "menuOpen"]),
    owner() {
      return {
        name: this.user?.name || "",
        // fio: this.user?.fio || "",
        email: this.user?.email || "",
      };
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["logout", "setMenuOpen"]),
    async handleLogout() {
      await this.logout();
      await this.$router.push("/main").catch(() => {}); // main or login...
    },
    toggleMenu() {
      this.setMenuOpen(!this.menuOpen);
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    horizonalmenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
  },
  // watch: {},
};
</script>

<style lang="scss" scoped>
.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70px;
  color: #fff;
  text-align: center;
  margin: 0 10px;
}
</style>
